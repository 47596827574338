import React from "react";
import "./Skill.css";

function Skill() {
    return (

        <>
            <div id="myskills">
                <div className="text-center">
                    <h1>Know my Skills</h1>
                </div>
                <div className="skill-container">
                    <h2 className="skill-heading">Technical Skills</h2>
                    <div className="row">
                        <div className="box">
                            <span className="letter">React</span>
                            <button className="view-button">View Project</button>
                        </div>
                        <div className="box">
                            <span className="letter">Bootstrap</span>
                            <button className="view-button">View Project</button>
                        </div>
                        <div className="box">
                            <span className="letter">HTML,CSS3</span>
                            <button className="view-button">View Project</button>
                        </div>
                        <div className="box">
                            <span className="letter">PHP</span>
                            <button className="view-button">View Project</button>
                        </div>
                        <div className="box">
                            <span className="letter">Javascript</span>
                            <button className="view-button">View Project</button>
                        </div>
                        <div className="box">
                            <span className="letter">Wordpress</span>
                            <button className="view-button">View Project</button>
                        </div>
                    </div>

                    <h2 className="skill-heading">Other Skills</h2>
                    <div className="row">
                        <div className="box">
                            <span className="letter">Canva</span>
                            <button className="view-button">View Project</button>
                        </div>
                        <div className="box">
                            <span className="letter">Flipbook</span>
                            <button className="view-button">View Project</button>
                        </div>
                        <div className="box">
                            <span className="letter">WPS</span>
                            <button className="view-button">View Project</button>
                        </div>
                        <div className="box">
                            <span className="letter">Github</span>
                            <button className="view-button">View Project</button>
                        </div>
                        <div className="box">
                            <span className="letter">Youtubemoviemaker</span>
                            <button className="view-button">View Project</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Skill;