import React from "react";
import "./Header.css";
import Pdf from "../Media/KavyaCV.pdf";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faEnvelope, faLinkedin, faYoutube);

function Header() {
  return (
    <>
      <header>
        <div className="logo">KavyaCV</div>        
        <nav>
          <ul>
            <li> <a href="#myskills">Skills</a></li>
            <li> <a href="#projects">Projects</a></li>
            <li> <a href="#experience">Experience</a></li>
            </ul>
            <ul>
            <li> <a href="#"> <FontAwesomeIcon icon="fa-solid fa-envelope" /></a></li>
            <li> <a href="#"> <FontAwesomeIcon icon="fa-brands fa-linkedin" /></a></li>
            <li> <a href="#"> <FontAwesomeIcon icon="fa-brands fa-youtube" /></a></li>
            </ul>
        </nav>
        <div className="hire">
          <button><a href={Pdf} target="_blank" download>Hire me</a></button>
        </div>
      </header>
    </>
  )
}
export default Header;