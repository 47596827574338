import './App.css';
import Header from './Components/Header/Header';
import Introduction from './Components/Introduction/Introduction';
import Skill from './Components/Skill/Skill';
import Project from './Components/Project/Project';


function App() {
  return (
    <div className="App">
      <Header/>
      <Introduction/>
      <Skill/>
      <Project/>

    </div>
  );
}

export default App;
