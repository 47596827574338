import React from "react";
import "./Introduction.css"
import Button from "../Button/Button"
import Intro from "../Media/intro_image.png";

function Introduction() {
    return (
        <>
            <div className="intro">
                <div className="intro-text">
                    <div>
                        <span>Hello world, I'm</span>
                        <p> <h1><strong>"Kavya C V"</strong></h1></p>
                        <span>A passionate <span className="role">website developer</span> embracing challenges.</span>
                        <Button text="Hire me"></Button>
                    </div>
                </div>
                <div className="intro-img">
                    <img src={Intro} />
                </div>
            </div>


        </>
    );
}

export default Introduction;