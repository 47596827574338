import React from "react";
import "./Button.css";
import Pdf from "../Media/KavyaCV.pdf";


function Button(props) {
    return (
        <>
            <div className="hire">
                <button> <a href={Pdf} target="_blank">{props.text}</a></button>
            </div>
        </>
    )
}
export default Button;