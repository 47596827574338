import React from "react";
import "./Project.css";

function Project() {
    return (

        <>
        <div id="projects">
            <div className="Projects">
                <div className="text-center">
                    <h1>Explore my work</h1>
                </div>                
            </div>
        </div>
        </>

    );
}

export default Project;